
export default function registerInfo( state=[],action ){
    switch (action.type){
        case "SAVE_REGISTER":
            return action.data;
        case "EDIT_REGISTER":
            return Object.assign({},state,action.data);
        default:
            return state
    }
}
import { combineReducers } from 'redux';
import companyID from './companyID';
import companyType from './companyType';
import enterSystem from './enterSystem';
import MessageCount from './messageCount';
import promptStatus from './promptStatus';
import registerInfo from './registerInfo';
import saveInfo from './saveInfo';
import showDot from './showDot';
import status from './status';
import stepInfo from './stepInfo';
import Template from './Template';
import timeInfo from './timeInfo';
import userInfo from './userInfo';
import userName from './userName';
import workBench from './workBench';

const reduce = combineReducers({
    userInfo,
    registerInfo,
    MessageCount,
    userName,
    companyID,
    timeInfo,
    promptStatus,
    enterSystem,
    Template,
    status,
    pageMes: saveInfo,
    workBench,
    stepInfo,
    showDot,
    companyType
});

export default reduce;

const initState = {
    companyId:''
}

export default function userInfo( state={},action ){
    switch (action.type){
        case "SAVE_USERINFO":
            return Object.assign({},state,{companyId:action.data})
        default:
            return state
    }
}
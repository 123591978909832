import { Modal } from 'antd';
import axios from 'axios';
import { logout } from './utils';

/**
 * 基于axios的http封装
 * 主要是做了拦截，添加了一些自定义的header信息
 * 做了一些报错优化
 */
const defaultMsg = '网络繁忙,请稍后再试';

// 抽离报错弹窗
const tipModal = (content, type) => {
    if (type == 'warn') {
        Modal.warn({
            title: '温馨提示',
            content,
            okText: '知道了',
            zIndex: 1005
        });
    } else {
        Modal.error({
            title: '温馨提示',
            content,
            okText: '知道了',
            zIndex: 1005
        });
    }
};

// 创建axios实例
const instance = axios.create();

// 设置post的头信息
instance.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8';
instance.defaults.headers.put['Content-Type'] = 'application/json;charset=UTF-8';

// 拦截请求并且添加config 添加头信息
instance.interceptors.request.use(
    (config) => {
        if (config.url && config.url.includes('undefined')) {
            return;
        } // 捕捉url是否有underfined
        // 取出token给headers中的自定义字段
        const token = window.localStorage.AUTHORIZATION;
        const client = window.localStorage.CLIENT;
        const companyId = window.localStorage.COMPANYID;
        token && (config.headers.Authorization = token);
        token && (config.headers.channel = client);
        companyId && (config.headers.COMPANYID = companyId);
        config.headers.COMPANYTYPE = 1;
        config.headers.ct = 23;
        return config;
    },
    (error) => Promise.error(error)
);

// 拦截请求，做错误状态码的报错
instance.interceptors.response.use(
    (res) => (res.status === 200 || res.status === 204 ? Promise.resolve(res) : Promise.reject(res)),
    (error) => {
        let tipType = 'error';
        const { response } = error;
        if (response) {
            // 标识了登录页面需要刷新的，不弹出错误提示
            const reloadLogin = window.sessionStorage.getItem('reloadLogin');
            if (reloadLogin === 'true') {
                return;
            }
            const { status } = response;
            if (status === 401) {
                logout();
                return;
            }

            let msg = status === 504 ? '系统正在处理中，请稍后' : defaultMsg;
            if (response.data.message) {
                msg = extractMsg(msg, response);
                if (status >= 400 && status < 500) {
                    tipType = 'error';
                }
            }
            const timer = setTimeout(() => {
                tipModal(msg, tipType);
            }, 0);
            error.hideToastError = () => {
                clearTimeout(timer);
            };
            throw error;
            // return Promise.reject(response)
        } else {
            // 隐藏掉,提示容易误导 以为系统错误
            // tipModal('网络已断开')
        }
    }
);

/**
 * 从response中提取返回的消息
 * @param msg
 * @param response
 * @returns {string}
 */
function extractMsg(msg, response) {
    msg = response.data.message;
    // 具体字段的错误信息汇总（有，则替代总的错误提示）
    const { fieldMsgs } = response.data;
    if (fieldMsgs && fieldMsgs.length > 0) {
        const msgArr = [];
        _.map(fieldMsgs, (i) => {
            if (i.message) {
                msgArr.push(i.message);
            }
        });
        if (msgArr.length > 0) {
            msg = [...new Set(msgArr)].join('；');
        }
    }
    return msg;
}

export default instance;

import './style/normalize.css';
import './style/public.less';

import '@babel/polyfill';
import request from '@common/http';
import { LocaleProvider, Modal } from 'antd';
import zh_CN from 'antd/lib/locale-provider/zh_CN';
import { ConfigProvider, Prompt } from 'hera-ui';
import 'moment/locale/zh-cn';
import React from 'react';
import ReactDom from 'react-dom';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import { logout } from './common/utils';
import { toMount } from './component/springFrame';
import reduce from './reduce/reduce';
import RouterComponent from './router/Pages';
// 删除指定index的数组，返回删除后的数组
Array.prototype.delete = function (delIndex) {
    const temArray = [];
    for (let i = 0; i < this.length; i++) {
        if (i != delIndex) {
            temArray.push(this[i]);
        }
    }
    return temArray;
};

String.prototype.ExChangePrice = function () {
    return this.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

String.prototype.replaceAll = function (reallyDo, replaceWith, ignoreCase) {
    if (!RegExp.prototype.isPrototypeOf(reallyDo)) {
        return this.replace(new RegExp(reallyDo, ignoreCase ? 'gi' : 'g'), replaceWith);
    } else {
        return this.replace(reallyDo, replaceWith);
    }
};
window._ = require('lodash');
window.$ = require('jquery');
window.API = require('./common/api');
window.QUERY_STRING = require('query-string');
window.Immutable = require('immutable');
toMount();
require('./common/extendAjax');

// console.log('当前环境变量:',process.env.NODE_ENVE)
window.enve = process.env.NODE_ENVE;

/**
 * 解析URL获取查询参数对象
 * @param queryString
 * @returns {ParsedQuery}
 * @constructor
 */
window.GET_PARAM = (queryString = window.location.href) => {
    const index = queryString.indexOf('?');
    if (index === -1) {
        return {};
    }
    const str = queryString.substring(index);
    return QUERY_STRING.parse(str);
};

/**
 * 向指定url中添加参数
 * @param url
 * @param param
 * @returns {string|*}
 * @constructor
 */
window.ADD_PARAM = (url, param) => {
    if (url.indexOf('?') === -1) {
        return `${url}?${param}`;
    }
    if (url.indexOf(param) === -1) {
        return url;
    }
    return `${url}&${+param}`;
};

// 获取get参数
const serilizeURL = (url) => {
    const rs = url.split('?')[1];
    const arr = rs.split('&');
    const json = {};
    for (let i = 0; i < arr.length; i++) {
        if (arr[i].indexOf('=') != -1) {
            json[arr[i].split('=')[0]] = arr[i].split('=')[1];
        } else {
            json[arr[i]] = 'undefined';
        }
    }
    return json;
};

/**
 * 解析URL获取查询数字参数，如果字符串中包含了非数字（小数点也算非数字）则剔除非数字
 * 因为现实情况，当用户复制粘帖url时，会不小心改动了url上的参数
 * @param key
 * @param queryString
 * @returns {ParsedQuery|*|null}
 * @constructor
 */
window.GET_NUM_PARAM = (key, queryString = window.location.href) => {
    const str = GET_PARAM(queryString)[key];
    if (/^\d+$/.test(str)) {
        return str;
    }
    if (str === null || str === undefined || typeof str === 'object') {
        return null;
    }

    // 从末尾开始剔除非数字
    let result = '';
    str.split('').forEach(function (char) {
        if (/^\d+$/.test(char)) {
            result += char;
        }
    });
    return result;
};

window.getParams = serilizeURL;
/**
 * 简便方法，从URL查询参数中获取ID
 * @param url
 * @returns {T[] | T | null | undefined}
 * @constructor
 */
window.GET_ID = (url = window.location.href) => {
    return GET_PARAM(url)['id'];
};

$(document).on('ajaxError', (e, response) => {
    if (response.status === 401) {
        window.localStorage.removeItem('AUTHORIZATION'); // authorization
        window.localStorage.removeItem('COMPANYID'); // authorization
        window.localStorage.removeItem('COMPANYNAME'); // authorization
        window.localStorage.removeItem('AUTHENTICATIONSTATUS'); // authorization
        window.localStorage.removeItem('USERNAME'); // authorization
        window.localStorage.removeItem('SHOWCREATE'); // authorization
        window.localStorage.removeItem('USERID'); // authorization
        window.localStorage.removeItem('SHOWENTER'); // authorization
        window.localStorage.removeItem('SYSTEM'); // authorization
        window.localStorage.removeItem('INDEX'); // authorization
        window.localStorage.removeItem('SELECTTYPE'); // authorization
        window.localStorage.removeItem('FREEZE'); // authorization
        window.localStorage.removeItem('MODULE_PERMIT');
        window.localStorage.removeItem('SYS_EDITION'); // 标准版/专业版

        // 放一个标识，登录页面需要刷新
        window.sessionStorage.setItem('reloadLogin', 'true');
        window.location.href = '/#/login';
        return false;
    }
});

window.addEventListener('storage', (e) => {
    if (e.key === 'USERID' && e.newValue !== e.oldValue) {
        if (window.location.hash !== '#/login') {
            Prompt.dredgeIntercept(() => {
                window.location.hash = '#/login';
            });
            Modal.warning({
                title: '温馨提示',
                content: '当前账号登录信息发生变更'
            });
        }
    }
});

const store = createStore(
    reduce,
    // 开发环境触发 redux-devtools
    window.__REDUX_DEVTOOLS_EXTENSION__ && process.env.NODE_ENV === 'development'
        ? window.__REDUX_DEVTOOLS_EXTENSION__()
        : undefined
);

const ConfigProviderProps = {
    request,
    table: {
        pagination: {
            showQuickJumper: true
        }
    }
};
ReactDom.render(
    <LocaleProvider locale={zh_CN}>
        <Provider store={store}>
            <ConfigProvider {...ConfigProviderProps}>
                <RouterComponent />
            </ConfigProvider>
        </Provider>
    </LocaleProvider>,
    document.getElementById('app')
);

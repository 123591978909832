
export default function stepInfo(state = { step: 1, showStep: false }, action) {
    switch (action.type) {
        case "SAVE_STEP":
            return Object.assign({}, state, action.data);
        case "CLEAR_STEP":
            return {};
        default:
            return state
    }
}
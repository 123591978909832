
export default function saveInfo( state={},action ){
    switch (action.type){
        case "SAVE_INFO":
            return Object.assign({},state,action.data);
        case "CLEAR_INFO":
            return {};
        default:
            return state
    }
}
import { Spin } from 'antd';
import React, { lazy, Suspense } from 'react';
import { HashRouter as Router, IndexRoute, Redirect, Route, Switch } from 'react-router-dom';
const Login = lazy(() => import('../container/login_action'));
const WorkbenchPDF = lazy(() => import('../Page/Home/WorkbenchExportPDF')); // 工作台
const RemoteVisitPDF = lazy(() => import('../Page/RemoteVisitPDF/RemoteVisitPDF'));
const BusinessLinePDF = lazy(() => import('../Page/BusinessLine/BusinessLinePDF'));
const MarketPlanIndexPDF = lazy(() => import('../Page/Market/MarketPlanIndexPDF'));
const SearchResultPage = lazy(() => import('../Page/SearchResult/SearchResultPage'));
const PreviewInformation = lazy(() => import('../Page/Product/PreviewInformation')); // 资讯预览
const StatisticsServiceProviderPDF = lazy(() => import('../Page/Business/StatisticsServiceProviderPDF'));
const ReportProviderPDF = lazy(() =>
    import(
        '../Page/Business/MoreStandard/qualificationReviewSetting/page/qualificationCheck/component/auditReport/page/reportProviderPDF'
    )
);

const App = lazy(() => import('../layout/layoutCom'));

const IndexBind = lazy(() => import('../weChatBind/IndexBind'));
const BindWechat = lazy(() => import('../weChatBind/BindWechat'));
const ChangeBind = lazy(() => import('../weChatBind/ChangeBind'));

export default React.memo(() => (
    <Router>
        <Suspense
            fallback={
                <Spin tip='Loading...'>
                    <div style={{ height: 500 }} />
                </Spin>
            }
        >
            <Switch>
                <Route exact path='/' render={() => <Redirect to='/login' />} />
                <Route path='/login' component={Login} />
                <Route exact path='/WorkbenchPDF' component={WorkbenchPDF} />
                <Route exact path='/RemoteVisitPDF' component={RemoteVisitPDF} />
                <Route exact path='/SearchResultPage' component={SearchResultPage} />
                <Route exact path='/BusinessLinePDF/:companyId/:id' component={BusinessLinePDF} />
                <Route exact path='/goalAndBudgetPDF' component={MarketPlanIndexPDF} />
                <Route exact path='/statisticsProviderPDF' component={StatisticsServiceProviderPDF} />
                <Route exact path='/previewInformation' component={PreviewInformation} />
                <Route exact path='/ReportProviderPDF' component={ReportProviderPDF} />

                <Route exact path='/IndexBind' component={IndexBind} />
                <Route exact path='/BindWechat' component={BindWechat} />
                <Route exact path='/ChangeBind' component={ChangeBind} />

                <Route path='/' component={App} />
            </Switch>
        </Suspense>
    </Router>
));
